import { observable, action, runInAction } from 'mobx';
import { commonApi } from '../services/common.service';

class CommonStore {
  @observable
  public shopList: Array<{ name: string; value: number }> = [];

  @observable
  public loading: boolean = false;

  // 产品列表拉取
  @action
  public getShopList = async (): Promise<number> => {
    this.loading = true;

    if (this.shopList.length) {
      this.loading = false;
      return this.shopList[0].value;
    }

    const res = await commonApi.fetchShopList();
    const shops = res.shops;
    runInAction(() => {
      this.shopList = shops.map((v: { name: string; shop_id: number }) => ({ name: v.name, value: v.shop_id }));
      this.loading = false;
    });

    return (shops[0] && shops[0].shop_id) || '';
  };
}

export const commonStore = new CommonStore();
export default CommonStore;
