import { http } from 'utils/http';
import { POST, GET, PUT } from 'utils/decorators/http';

class ProcurementApi {

  // 获取订单列表(生成采购单页面)
  @GET({
    url: '/order'
  })
  async orderForProcurement(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // 获取筛选条件
  @GET({
    url:'/purchase/filter_option'
  })
  async filter(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // 生成采购单
  @POST({
    url: '/purchase'
  })
  async create(opt?: any) {
      const res = await http(opt);
      return Promise.resolve(res.data);
  }

  // 获取采购单
  @GET({
    url: '/purchase'
  })
  async getProList(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // 重新生成采购单
  @PUT({
    url:'/purchase'
  })
  async reCreate(opt?:any){
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // 下载采购单
  @GET({
    url: '/purchase/download',
    responseType: 'blob'
  })
  async downloading(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // 采购单详情
  @GET({
    url: '/purchase'
  })
  async procurementDetail(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // 更新采购状态
  @POST({
    url: '/purchase/status'
  })
  async updatePurchaseStatus(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // 采购状态 API
  @GET({
    url: '/purchase/status'
  })
  async fetchPurchaseStatus(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // 物流公司 API
  @GET({
    url: '/purchase/company'
  })
  async fetchShippingCompany(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // 采购员 API
  @GET({
    url: '/purchase/buyer'
  })
  async fetchBuyer(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }
}

//------------------------------------------------------------------ (o≖◡≖)

export const procurementApi = new ProcurementApi();
