import { IMenu } from './loadable.config';
import { signOut } from './menuFuncs/signOut';

export const menu: IMenu[] = [
  {
    key: '1',
    title: '产品管理',
    icon: 'shop'
  },
  {
    key: '11',
    parentKey: '1',
    title: '产品列表',
    icon: 'unordered-list',
    path: '/goods/goodslist',
    component: 'GoodsList',
    exact: true
  },
  {
    key: '111',
    parentKey: '11',
    title: '产品详情',
    icon: 'unordered-list',
    path: '/goods/goodsdetail',
    component: 'GoodsDetail',
    exact: true,
    noNav: true
  },
  {
    key: '2',
    title: '订单管理',
    icon: 'shopping'
  },
  {
    key: '21',
    parentKey: '2',
    title: '订单列表',
    icon: 'unordered-list',
    path: '/order/orderlist',
    component: 'OrderList',
    exact: true
  },
  {
    key: '211',
    parentKey: '21',
    title: '产品详情',
    icon: 'unordered-list',
    path: '/order/goodsdetail',
    component: 'GoodsDetail',
    exact: true,
    noNav: true
  },
  {
    key: '22',
    parentKey: '2',
    title: '操作日志',
    icon: 'file-text',
    path: '/order/orderoperation',
    component: 'OrderOperation',
    exact: true
  },
  {
    key: '3',
    title: '采购管理',
    icon: 'transaction'
  },
  {
    key: '31',
    parentKey: '3',
    title: '生成采购单',
    icon: 'block',
    path: '/procurement/createprocurement',
    component: 'CreateProcurement',
    exact: true,
  },
  {
    key: '32',
    parentKey: '3',
    title: '采购单列表',
    icon: 'unordered-list',
    path: '/procurement/procurementlist',
    component: 'ProcurementList',
    exact: true,
  },
  {
    key: '321',
    parentKey: '32',
    title: '采购单详情',
    icon: 'unordered-list',
    path: '/procurement/procurementdetail/:id',
    component: 'ProcurementDetail',
    exact: true,
    noNav: true,
  },
  {
    key: '4',
    title: '设置',
    icon: 'setting',
    path: '/setting',
    component: 'Setting',
    exact: true
  },
  {
    key: '41',
    parentKey: '4',
    title: '退出登录',
    icon: 'logout',
    func: signOut
  }
];
