import { http } from 'utils/http';
import { GET, POST } from 'utils/decorators/http';

class GoodsApi {
  /**
   * 产品列表
   * @param params
   */
  @GET({
    url: '/product'
  })
  async fetchProductList<T = any>(params: any) {
    const res = await http.request<T>(params);
    return res.data;
  }

  /**
   * 产品详情
   * @param params
   */
  @GET({
    url: '/product'
  })
  async fetchProductDetail<T = any>(id: any) {
    const res = await http.request<T>(id);
    return res.data;
  }

  /**
   * 品类
   * @param params
   */
  @GET({
    url: '/product/category'
  })
  async fetchCategory<T = any>(params?: any) {
    const res = await http.request<T>(params);
    return res.data;
  }

  /**
   * 商品采购人列表
   * @param params
   */
  @GET({
    url: 'product/purchase_director'
  })
  async fetchPurchase_director<T = any>(params?: any) {
    const res = await http.request<T>(params);
    return res.data;
  }

  /**
   * 产品修改
   * @param params
   */
  @POST({
    url: '/product'
  })
  async updateProduct<T = any>(params: any) {
    const res = await http.request<T>(params);
    return res.data;
  }
}

export const goodsApi = new GoodsApi();
