/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-09 15:43:40
 * @LastEditTime: 2019-08-15 09:31:25
 * @LastEditors: Please set LastEditors
 */
import { action, observable, runInAction } from "mobx";
import { orderApi } from '../services/order.service';
import { ORDER_API_PARAMS, ORDER_OPERATION_API_PARAMS, ORDER_STATUS_STRING, LOGISTICS_STATUS_STRING } from '../utils/enum';
import { IKV } from 'interface';
import { OrderData, OrderOperationData, OrderSourceData, ProductInfo } from 'interface/order.interface';
import moment from 'moment';
// import { resolve } from "url";
// import { reject } from "q";

const {
  shopId,
  orderStatus,
  shopifyOrderId,
  orderId,
  logisticsStatus,
  shippingNumber,
  shopifyProductId
} = ORDER_API_PARAMS;

const { startDate, endDate } = ORDER_OPERATION_API_PARAMS;

export class OrderStore {
  @observable
  orderData: OrderData[] = [];

  @observable
  public orderState: IKV = {
    loading: false,
    defaultPageSize: 10,
    currentPage: 1,
    totalNumber: 0,
  };

  @observable
  public currentShopId: string = '';

  @observable
  public shippingCompanyList: Array<{ name: string, value: number }> = [];

  @observable
  public orderIdList: Array<{ name: string, value: number }> = [];

  @observable
  public shopifyIdList: Array<{ name: string, value: number }> = [];

  @observable
  public shippingNumberList: Array<{ name: string, value: number }> = [];

  @observable
  public productIdList: Array<{ name: string, value: number }> = [];

  @observable
  public getShippingNumberLoading = false;

  @observable
  public getShippingCompanyLoading = false;

  @observable
  public updateLogisticsStatusLoading = false;

  @observable
  public reloadLoading = false;

  @observable
  public downloadLoading = false;

  @observable
  public orderOperationData: OrderOperationData[] = [];

  @observable
  public orderOperationState = {
    loading: false,
    defaultPageSize: 20,
    totalNumber: 0,
  };

  @action
  public setCurrentPage = (page: number) => {
    this.orderState.currentPage = page;
  }

  @action
  public setCurrentShopId = (id: string) => {
    this.currentShopId = id;
  }

  @action
  public getOrderList = async (
    params: {
      [orderStatus]?: string
      [shopifyOrderId]?: string,
      [orderId]?: string,
      [logisticsStatus]?: string,
      [shippingNumber]?: string,
      [shopifyProductId]?: string,
      page?: number,
      size?: number,
  }): Promise<{ success: boolean, error?: string }> => {
    this.orderState.loading = true;
    params[shopId] = this.currentShopId;

    try {
      const res = await orderApi.fetchOrderDate(params);
      const { data } = res;
      const dataList: OrderData[] = data.map((d: OrderSourceData) => {

        const orderInfoList: Array<{
          orderId: number,
          productInfo: ProductInfo[]
          company: string,
          labelPaperUrl: string,
          shippingNumber: string,
        }> = [];
        const orderIdSet = new Set();

        d.line_items.forEach((item: IKV, index: number) => {
          const productInfoList: ProductInfo[] = [];
          const shippingItemLen = d.shipping_items.length;

          productInfoList.push({
            productId: item.product_id,
            skuName: item.sku,
            skuId: item.variant_id,
            titleCN: item.title_cn,
            titleEN: item.title_en,
          })

          if (!orderIdSet.has(item.id)) {
            orderInfoList.push({
              orderId: item.id,
              productInfo: productInfoList,
              company: (shippingItemLen >= index + 1) ? d.shipping_items[index].company : '',
              labelPaperUrl: (shippingItemLen >= index + 1) ? d.shipping_items[index].label_paper_url : '',
              shippingNumber: (shippingItemLen >= index + 1) ? d.shipping_items[index].shipping_number: '',
            })
            orderIdSet.add(item.id);
          } else {
            orderInfoList.forEach((orderInfo: IKV, index: number) => {
              if (orderInfo.orderId === item.id) {
                orderInfoList[index].productInfo.push(productInfoList[0]);
              }
            })
          }
        })

        return {
          shopifyOrderId: d.order_id,
          orderStatus: ORDER_STATUS_STRING[d.order_status],
          logisticsStatus: LOGISTICS_STATUS_STRING[d.fulfillment_status],
          orderInfo: orderInfoList,
          shipInfo: {
            name: d.shipping_address.name,
            email: d.email ? d.email : '',
            phone: d.shipping_address.phone,
            zip: d.shipping_address.zip ? d.shipping_address.zip : '',
            address1: d.shipping_address.address1,
            address2: d.shipping_address.address2,
          },
          country: d.shipping_address.country,
          price: d.price,
          createTime: moment(moment(d.created_at)).format('YYYY-MM-DD HH:mm:ss'),
        }
      })

      runInAction(() => {
        this.orderState.loading = false;
        this.orderData = dataList;
        this.orderState.totalNumber = res.total;
      });

      return {
        success: true,
      };
    } catch (e) {
      runInAction(() => {
        this.orderState.loading = false;
      });

      return {
        error: '获取订单列表失败',
        success: false,
      };
    }
  };

  @action
  public getShippingNumber = async (
    params: {
      ids: string,
      companysid: string,
  }): Promise<{ success: boolean, error?: string }> => {
    this.getShippingNumberLoading = true;
    try {
      const res = await orderApi.fetchShippingNumber(params);

      runInAction(() => {
        this.getShippingNumberLoading = false;
      });

      for (let [key, value] of Object.entries(res.data)) {
        if (value !== 'true') {
          return { error: String(key) + ': ' + value, success: false };
        }
      }

      return {
        success: true,
      };
    } catch (e) {
      runInAction(() => {
        this.getShippingNumberLoading = false;
      });

      const error = e.data.message ? e.data.message : '获取物流单号失败';
      return {
        error,
        success: false,
      };
    }
  };

  @action
  public getShippingCompanyList = async (
    params: {
      page?: number,
      size?: number,
  }): Promise<{ success: boolean, error?: string }> => {
    this.getShippingCompanyLoading = true;
    try {
      const res = await orderApi.fetchShippingCompanyList(params);

      const companyList = res.companys.map((item: IKV) => {
        return {
          name: item.name,
          value: item.code,
        }
      })

      runInAction(() => {
        this.shippingCompanyList = companyList;
        this.getShippingCompanyLoading = false;
      });

      return {
        success: true,
      };
    } catch (e) {
      runInAction(() => {
        this.getShippingCompanyLoading = false;
      });

      return {
        error: '获取运输渠道失败',
        success: false,
      };
    }
  }

  @action
  public updateLogisticsStatus = async (
    params: {
      [orderId]: number[],
      [shopifyOrderId]: number[],
      [logisticsStatus]: number,
      product_info?: {
        [shopifyProductId]: number,
        variant_id: number,
      }[]
  }): Promise<{ success: boolean, error?: string }> => {
    this.updateLogisticsStatusLoading = true;

    try {
      await orderApi.updateLogisticsStatus(params);

      runInAction(() => {
        this.updateLogisticsStatusLoading = false;
      });

      return {
        success: true,
      };
    } catch (e) {
      runInAction(() => {
        this.updateLogisticsStatusLoading = false;
      });

      const error = e.data.message ? e.data.message : '跟新物流状态失败';
      return {
        error,
        success: false,
      };
    }
  };
  @action
  public reloadData = async (
    params: {
      [shopifyOrderId]: string,
  }): Promise<{ success: boolean, error?: string }> => {
    this.reloadLoading = true;
    params[shopId] = this.currentShopId;

    try {
      await orderApi.reloadData(params);

      runInAction(() => {
        this.reloadLoading = false;
      });

      return {
        success: true,
      };
    } catch (e) {
      runInAction(() => {
        this.reloadLoading = false;
      });

      return {
        error: '重新生成出库单失败',
        success: false,
      };
    }
  };

  @action
  public downloadInvoice = async (): Promise<{ success: boolean, error?: any }> => {
    this.downloadLoading = true;
    try {
      const res = await orderApi.downloadInvoice({ time: new Date().getTime() });
      runInAction(() => {
        this.downloadLoading = false;
        const element = document.createElement("a");
        const file = new Blob([res]);
        element.href = URL.createObjectURL(file);
        element.download = `发货单_${moment().format("YYYYMMDDHHmm")}.xlsx`;
        element.click();
      });
      return res;
    } catch (e) {
      runInAction(() => {
        this.downloadLoading = false;
      });
      // 获取错误信息
      let getError = async (data: any) => {
        return new Promise((resolve,) => {
          let reader = new FileReader();
          reader.onload = (eve: any) => {
            const errData = JSON.parse(eve.target.result);
            console.log(errData);
            const error = errData.message ? errData.message : '请重试！导出发货单失败';
            resolve(error)
          };
          reader.readAsText(data)
        })
      };
      const error = await getError(e.data);
      return {
        error,
        success: false
      };
    }
  };

  @action
  public getShippingNumberList = async (
    params: {
      [shippingNumber]?: string,
      field?: string,
      page?: number,
      size?: number,
  }): Promise<{ success: boolean, error?: string }> => {
    try {
      params[shopId] = this.currentShopId;
      params.size = 99999;
      const res = await orderApi.fetchShippingNumberList(params);

      const shippingNumbers = res.data.map((item: IKV) => {
        return {
          name: String(item.shipping_number),
          value: item.shipping_number,
        }
      })

      runInAction(() => {
        this.shippingNumberList = shippingNumbers
      });

      return {
        success: true,
      };
    } catch (e) {
      runInAction(() => {

      });

      return {
        error: '获取物流单号列表失败',
        success: false,
      };
    }
  }

  @action
  public getShopifyOrderIdList = async (
    params: {
      [shopifyOrderId]?: string,
      field?: string,
      page?: number,
      size?: number,
  }): Promise<{ success: boolean, error?: string }> => {
    try {
      params[shopId] = this.currentShopId;
      params.size = 99999;
      const res = await orderApi.fetchShopifyOrderIdList(params);

      const orderIds = res.data.map((item: IKV) => {
        return {
          name: String(item.order_id),
          value: item.order_id,
        }
      })

      runInAction(() => {
        this.shopifyIdList = orderIds;
      });

      return {
        success: true,
      };
    } catch (e) {
      runInAction(() => {

      });

      return {
        error: '获取shopify订单ID列表失败',
        success: false,
      };
    }
  }

  @action
  public getProductIdList = async (
    params: {
      [shopifyProductId]?: string,
      field?: string,
      page?: number,
      size?: number,
  }): Promise<{ success: boolean, error?: string }> => {
    params[shopId] = this.currentShopId;
    params.size = 99999;
    try {
      const res = await orderApi.fetchProductIdList(params);

      const productIds = res.data.map((item: IKV) => {
        return {
          name: String(item.product_id),
          value: item.product_id,
        }
      })

      runInAction(() => {
        this.productIdList = productIds;
      });

      return {
        success: true,
      };
    } catch (e) {
      runInAction(() => {

      });

      return {
        error: '获取产品ID列表失败',
        success: false,
      };
    }
  }

  @action
  public getOrderIdList = async (
    params: {
      [orderId]?: string,
      field?: string,
      page?: number,
      size?: number,
  }): Promise<{ success: boolean, error?: string }> => {
    params[shopId] = this.currentShopId;
    params.size = 99999;
    try {
      const res = await orderApi.fetchOrderIdList(params);

      const ids = res.data.map((item: IKV) => {
        return {
          name: String(item.id),
          value: item.id,
        }
      })

      runInAction(() => {
        this.orderIdList = ids;
      });

      return {
        success: true,
      };
    } catch (e) {
      runInAction(() => {

      });

      return {
        error: '获取ID列表失败',
        success: false,
      };
    }
  }

  @action
  public getOrderOperationList = async (
    params: {
      [startDate]?: string,
      [endDate]?: string,
      page?: number,
      size?: number,
  }): Promise<{ success: boolean, error?: string }> => {
    this.orderOperationState.loading = true;

    try {
      const res = await orderApi.fetchOperationLog(params);

      const resData: OrderOperationData[] = res.data.map((item: IKV) => {
        return {
          id: item.id,
          user: item.user,
          count: item.count,
          time: moment(item.time * 1000).format('YYYY-MM-DD HH:mm:ss'),
        }
      })

      runInAction(() => {
        this.orderOperationState.loading = false;
        this.orderOperationData = resData;
        this.orderOperationState.totalNumber = res.total;
      });

      return {
        success: true,
      };
    } catch (e) {
      runInAction(() => {
        this.orderOperationState.loading = false;
      });

      return {
        error: '获取操作日志失败',
        success: false,
      };
    }
  };
}

export const orderStore = new OrderStore();
