import * as React from 'react';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import loadable from 'react-loadable';
import { PrivateRoute } from 'components/HOC/PrivateRoute';
import BasicLayout from 'components/BasicLayout';
// import CreateProcurement from './Procurement/CreateProcurement';
// import ProcurementList from './Procurement/ProcurementList';
// import ProcurementDetail from './Procurement/ProcurementDetail';
// import GoodsList from './Goods/GoodsList';
// import GoodsDetail from './Goods/GoodsDetail';
// import OrderList from './Order/OrderList';
// import OrderOperation from './Order/OrderOperation';
import { menu } from 'config/menu.config';
import { loadableComponents } from 'config/loadable.config';

interface Props {}

export class App extends React.Component<Props> {
  public Login = loadable({
    loader: () => import(/* webpackChunkName: "login" */ 'pages/Login/index'),
    loading: () => null
  });

  public privateRouteRender = () => (
    <BasicLayout>
      <Switch>
        {menu.map(i =>
          i.path && i.component ? (
            <Route key={i.key} exact={i.exact} path={i.path} component={loadableComponents[i.component]} />
          ) : null
        )}
      </Switch>
    </BasicLayout>
  );

  public render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/login" component={this.Login} />
          <Route path="/" exact render={() => <Redirect to={'/goods/goodslist'} />} />
          <PrivateRoute path="/" component={this.privateRouteRender} />
        </Switch>
      </Router>
    );
  }
}
