import loadable from 'react-loadable';
import { Loading } from 'components/common/Loading';

const handleloadableComponent = (component: () => Promise<any>) =>
  loadable({
    loader: component,
    loading: Loading
  });

export const loadableComponents = {
  // goods
  GoodsDetail: handleloadableComponent(() =>
    import(/* webpackChunkName: "GoodsDetail" */ '../pages/Goods/GoodsDetail')
  ),
  GoodsList: handleloadableComponent(() => import(/* webpackChunkName: "GoodsList" */ '../pages/Goods/GoodsList')),
  // order
  OrderList: handleloadableComponent(() => import(/* webpackChunkName: "OrderList" */ '../pages/Order/OrderList')),
  OrderOperation: handleloadableComponent(() =>
    import(/* webpackChunkName: "OrderOperation" */ '../pages/Order/OrderOperation')
  ),
  // procurement
  CreateProcurement: handleloadableComponent(() =>
    import(/* webpackChunkName: "CreateProcurement" */ '../pages/Procurement/CreateProcurement')
  ),
  ProcurementDetail: handleloadableComponent(() =>
    import(/* webpackChunkName: "ProcurementDetail" */ '../pages/Procurement/ProcurementDetail')
  ),
  ProcurementList: handleloadableComponent(() =>
    import(/* webpackChunkName: "ProcurementList" */ '../pages/Procurement/ProcurementList')
  ),
  // setting
  Setting: handleloadableComponent(() => import(/* webpackChunkName: "Setting" */ '../pages/Setting'))
};

export type LoadableComponentsTypeKeys = keyof typeof loadableComponents;

export interface IMenu {
  key: string;
  parentKey?: string;
  title: string;
  icon?: string;
  path?: string;
  component?: LoadableComponentsTypeKeys;
  exact?: boolean;
  noNav?: boolean;
  func?: () => void;
  // children?: any[];
}

export interface IMenuTree extends IMenu {
  children?: IMenu[];
}
