import { http } from 'utils/http';
import { POST, HandlePanic } from 'utils/decorators/http';
import { IKV } from 'interface';

class UserApi {
  @POST({
    url: '/login'
  })
  async login<T = any>(params?: any, headers?: IKV, handle?: HandlePanic<T>) {
    const res = await http.request<T>(params);
    if (handle) {
      const r = handle(res.data);
      return Promise.resolve(r);
    }
    return Promise.resolve(res.data);
  }
}

export const userApi = new UserApi();
