import React, { Component } from 'react';
import { Layout, Breadcrumb } from 'antd';
import { LeftMenu } from './LeftMenu';
import { menu } from 'config/menu.config';
// import styles from './index.module.scss';
import { inject, observer } from 'mobx-react';
import { IStore } from 'store';
import { RouterStore } from 'store/router.store';
import { withRouterProps } from 'components/HOC/WithRouterProps';
import { IMenu } from 'config/loadable.config';
import { Link } from 'react-router-dom';
import { matchingRate } from 'utils/tool';

const { Content, Sider } = Layout;

interface Props extends IWithRouterProps {
  routerStore?: RouterStore;
}

@withRouterProps
@inject((store: IStore) => {
  const { routerStore } = store;
  return {
    routerStore
  };
})
@observer
class BasicLayout extends Component<Props> {
  public state = {
    collapsed: false,
  };

  public getSnapshotBeforeUpdate() {
    const { location, routerStore } = this.props;
    routerStore!.update(location);
    return true;
  }

  public componentDidUpdate() {}

  public componentDidMount() {
    const { location, routerStore } = this.props;
    routerStore!.update(location);
  }

  /**
   * 根据匹配度来筛选选中的菜单
   *
   * @private
   * @param {string} cur
   * @returns {IMenu[]}
   * @memberof BasicLayout
   */
  private handleBread(cur: string, r: IMenu[] = [], len: number = 0, maxRate: number = 0): IMenu[] {
    if (!r.length) {
      let t = 0;
      menu.forEach(i => {
        const rate = matchingRate(cur, i.path || '');
        if (rate > t && rate > 1) {
          t = rate;
          maxRate = rate;
          r[0] = i;
        }
      });
    }

    if (r[0] && r[0].parentKey) {
      if (len === 0) {
        len = r[0].parentKey.length;
      }

      for (let i = 0, le = menu.length; i < le; i++) {
        if (menu[i].key.length === len && r[0].parentKey === menu[i].key) {
          r.unshift(menu[i]);
          break;
        }
      }
    }
    if (len && len > 1) {
      this.handleBread(cur, r, len - 1, maxRate);
    }

    return r;
  }

  public breadRender = () => {
    const { currentRouter } = this.props.routerStore!;

    const r = currentRouter ? this.handleBread(currentRouter.pathname) : [];
    return (
      <Breadcrumb>
        <Breadcrumb.Item>首页</Breadcrumb.Item>
        {r.map((i, idx) => {
          if (idx === r.length - 1 || !i.path) {
            return <Breadcrumb.Item key={idx}>{i.title}</Breadcrumb.Item>;
          }
          return (
            <Breadcrumb.Item key={idx}>
              <Link to={i.path!}>{i.title}</Link>
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  };

  public toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (
      <div className="h100">
        <Layout className="h100">
          {/* <Header className="header">
            <div className={styles.logo}>Panda 电商后台管理系统</div>
          </Header> */}
          {/* <Content className="flex-column">
            <Layout style={{ background: '#fff' }} className="flex-1 overflow-auto"> */}
              <Sider
                collapsible
                trigger={null}
                width={200}
                style={{ background: '#fff' }}
                collapsed={this.state.collapsed}
              >
                <LeftMenu toggle={this.toggle} collapsed={this.state.collapsed}/>
              </Sider>
            <Layout style={{ background: '#fff' }} className="flex-1 overflow-auto">
              <Content className="flex-1 overflow-auto flex-column">
                <div style={{ padding: '10px 16px', borderBottom: '1px solid #e8e8e8' }}>{this.breadRender()}</div>
                <div className="h100 flex-1 overflow-auto" style={{ padding: 16, minWidth: 1300 }}>
                  {this.props.children}
                </div>
              </Content>
              {/* <Footer style={{ textAlign: 'center', padding: 6 }}>
                Panda 电商后台管理系统 ©2019 Created by Palmax
              </Footer> */}
            </Layout>
          {/* </Content> */}
        </Layout>
      </div>
    );
  }
}

export default BasicLayout;
