import { userStore } from './user.store';
import { commonStore } from './common.store';
import { goodsStore } from './goods.store';
import { procurementStore } from './procurement.store';
import { orderStore } from './order.store';
import { routerStore } from './router.store';

export const store = {
  routerStore,
  userStore,
  commonStore,
  goodsStore,
  procurementStore,
  orderStore,
};

export default store;

export type IStore = typeof store;
