import { observable, action, runInAction } from 'mobx';
import { goodsApi } from '../services/goods.service';

// 产品接口
export interface Product {
  image: string;
  is_update: number;
  price: string;
  title: string;
  product_created_at: string;
  product_id: number;
  product_shopify_url: string;
  opponent_url?: string;
  remark?: string;
  suplier_price?: number;
  suplier_url?: string;
  title_cn?: string;
  title_en?: string;
  category?: string;
  declare_price?: number;
  is_electricity?: number;
  is_liquid?: number;
  packing?: string;
  purchase_director?: string;
  purchase_price?: number;
  quality?: string;
  sku_info?:string[]
}

// 默认下拉假数据
export default class GoodsStore {
  // shopif_id下拉
  @observable
  public shopifyIdList: number[] = [];

  @observable
  public shopifyIdLoading: boolean = false;

  // 标题下拉
  @observable
  public titleList: { product_id: number; title: string }[] = [];

  @observable
  public titleLoading: boolean = false;

  // 品类
  @observable
  public categoryList: string[] = [];

  // 采购人列表
  @observable
  public purchase_directorList: string[] = [];

  // 产品列表条目总数
  @observable
  public total: number = 0;

  // 商品列表数据
  @observable
  public productList: Product[];

  @observable
  public tableLoading: boolean = false;

  // 产品详情
  @observable
  public productDetail: Product | null;

  // 当前选中的店铺
  public currentShop: number | null;

  /**
   * @description 查询shopiyidList
   * @params {params} 查询字符串参数
   */
  @action
  public getShopifyIdList = async (params: {
    shop_id: string;
    search_product_id?: string;
    field?: string;
    page?: number;
    size?: number;
  }): Promise<void> => {
    params.field = 'product_id';
    params.size = 99999;
    this.shopifyIdLoading = true;
    const res = await goodsApi.fetchProductList(params);
    runInAction(() => {
      this.shopifyIdList = res.data;
      this.shopifyIdLoading = false;
    });
  };

  /**
   * @description 查询titleidList
   * @params {params} 查询字符串参数
   */
  @action
  public getTitleList = async (params: {
    shop_id: string;
    search_title?: string;
    field?: string;
    page?: number;
    size?: number;
  }): Promise<void> => {
    params.field = 'title';
    params.size = 99999;
    this.titleLoading = true;
    const res = await goodsApi.fetchProductList(params);
    runInAction(() => {
      this.titleList = res.data;
      this.titleLoading = false;
    });
  };

  // 产品列表拉取
  @action
  public getProductList = async (params: {
    shop_id: string;
    search_title?: string[];
    product_ids?: string[] | string;
    is_update?: string;
    page?: number;
    size?: number;
  }): Promise<{ success: boolean; error?: string }> => {
    this.tableLoading = true;
    // 将search_title 和 product_id中的id取交集，并查询结果
    // 交集结果必须全等于较少的一个id数组方可查询出数据，否则直接返回空
    // 如果两个id集合有一个为空将不作比较，直接作为查询参数
    const title_ids = params.search_title! as string[],
      product_ids = params.product_ids! as string[];

    const turple = [title_ids, product_ids],
      lessIdsIndex = title_ids.length > product_ids.length ? 1 : 0,
      lessIds = turple[lessIdsIndex],
      moreIds = turple[(lessIdsIndex + 1) % 2],
      hasSameSearchIds: boolean = lessIds.every((id: string) => moreIds.includes(id));

    if (!lessIds.length) {
      params.product_ids = moreIds.join(',');
    } else if (!hasSameSearchIds) {
      runInAction(() => {
        this.productList = [];
        this.total = 0;
        this.tableLoading = false;
      });
      return { success: true };
    } else {
      params.product_ids = lessIds.join(',');
    }

    try {
      // 过滤search_title字段
      const res = await goodsApi.fetchProductList({
        shop_id: params.shop_id,
        product_ids: params.product_ids,
        is_update: params.is_update || undefined,
        page: params.page,
        size: params.size
      });

      runInAction(() => {
        this.productList = res.data;
        this.total = res.total;
        this.tableLoading = false;
      });

      return { success: true };
    } catch (e) {
      runInAction(() => {
        this.tableLoading = false;
      });
      return {
        error: '产品列表拉取失败，请稍后重试',
        success: false
      };
    }
  };

  // 当前店铺设置
  @action
  public setCurrentShop = (shop_id: number | null) => {
    this.currentShop = shop_id;
  };

  // 商品详情查询
  @action
  public setProduct = async (id: number): Promise<{ success: boolean; error?: string }> => {
    try {
      const res = await goodsApi.fetchProductDetail(id.toString());
      runInAction(() => {
        if (res) {
          console.log(res)
          res.data.product_id = id;
          this.productDetail = res.data;
        }
      });
      return { success: true };
    } catch (e) {
      console.warn(e);
      return {
        error: '查看详情失败，请稍后重试',
        success: false
      };
    }
  };

  // 商品详情清空
  @action
  public setProductNull: () => void = () => {
    this.productDetail = null;
  };

  // 商品种类
  @action
  public getCategoryList = async (): Promise<void> => {
    const res = await goodsApi.fetchCategory();
    runInAction(() => {
      this.categoryList = res.categorys;
    });
  };

  // 采购负责人
  @action
  public getPurchase_directorList = async (): Promise<void> => {
    const res = await goodsApi.fetchPurchase_director();
    runInAction(() => {
      this.purchase_directorList = res.purchase_director;
    });
  };

  // 商品提交
  @action
  public updateProduct = async (product: Product): Promise<{ success: boolean; error?: string }> => {
    try {
      const numFormatFields: string[] = [
          'suplier_price',
          'purchase_price',
          'declare_price',
          'is_electricity',
          'is_liquid'
        ],
        trimFormatFields: string[] = ['opponent_url', 'suplier_url'];
      // 部分string字段类型转number
      numFormatFields.forEach(field => {
        product[field] = Number(product[field]);
      });

      // 部分链接部分去掉空格
      trimFormatFields.forEach(field => {
        product[field] = product[field].trim() || '';
      });

      await goodsApi.updateProduct(product);
      return { success: true };
    } catch (e) {
      console.warn(e);
      return {
        error: '产品修改失败，请稍后重试',
        success: false
      };
    }
  };
}

export const goodsStore = new GoodsStore();
