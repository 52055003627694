import * as React from 'react';
import { Menu, Icon } from 'antd';
import { withRouterProps } from 'components/HOC/WithRouterProps';
import { menu } from 'config/menu.config';
import { computed, reaction, IReactionDisposer } from 'mobx';
import { handleTree } from 'utils/tool';
import { ClickParam } from 'antd/lib/menu';
import { IMenuTree } from 'config/loadable.config';
import { inject, observer } from 'mobx-react';
import { IStore } from 'store';
import { RouterStore } from 'store/router.store';
import Logo from 'assets/img/panda.png';
import styles from './index.module.scss';

const { SubMenu } = Menu;
const MenuItem = Menu.Item;

interface Props extends IWithRouterProps {
  routerStore?: RouterStore;
  collapsed: boolean;
  toggle: () => void;
}

interface State {
  selectedKeys: string[];
  openKeys: string[];
}

@withRouterProps
@inject((store: IStore) => {
  const { routerStore } = store;
  return {
    routerStore
  };
})
@observer
export class LeftMenu extends React.Component<Props, State> {
  routerDisposer: IReactionDisposer;

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedKeys: [],
      openKeys: []
    };

    this.routerDisposer = reaction(
      () => props.routerStore!.currentRouter,
      cur => {
        this.changeMenu(cur.pathname);
      }
    );
  }

  public componentWillMount() {
    this.changeMenu(this.props.location!.pathname);
  }

  public componentWillUnmount() {
    this.routerDisposer();
  }

  @computed
  get menuTree() {
    const tree = handleTree(menu);
    return tree;
  }

  public menuRender = (menuTree: IMenuTree[]) => {
    return menuTree.map(i => {
      if (i.noNav) {
        return null;
      }

      if (i.children && i.children.length) {
        const children = this.menuRender(i.children);

        if (children.every(i => i)) {
          return (
            <SubMenu
              key={i.key}
              style={{ marginBottom: 16 }}
              title={
                <span>
                  {i.icon && <Icon type={i.icon} />}
                  <span>{i.title}</span>
                </span>
              }
            >
              {children}
            </SubMenu>
          );
        }
      }

      return (
        <MenuItem key={i.key}>
          {i.icon && <Icon type={i.icon} />}
          <span>{i.title}</span>
        </MenuItem>
      );
    });
  };

  public handleClickItem = ({ key }: ClickParam) => {
    const { history } = this.props;
    const selectedMenu = menu.find(i => key === i.key);
    if (!selectedMenu) {
      return;
    }

    if (selectedMenu.func) {
      selectedMenu.func();
    }

    if (selectedMenu.path) {
      history.push(selectedMenu.path);
    } else {
      this.setState({
        openKeys: [key]
      });
    }
  };

  public changeMenu = (path: string) => {
    const selectedMenu = menu.find(i => path === i.path);
    if (!selectedMenu || selectedMenu.noNav) {
      return;
    }

    if (selectedMenu.parentKey) {
      this.setState({
        openKeys: [selectedMenu.parentKey],
        selectedKeys: [selectedMenu.key]
      });
    } else {
      this.setState({
        selectedKeys: [selectedMenu.key]
      });
    }
  };

  public render() {
    const { toggle, collapsed } = this.props!;
    const { openKeys, selectedKeys } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <img src={Logo} alt='logo' className={collapsed ? styles.logoSmall : styles.logo} />
          {collapsed ? undefined : <div>Panda</div>}
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={selectedKeys}
          defaultOpenKeys={openKeys}
          style={{ height: '100%' }}
          onClick={this.handleClickItem}
        >
          {this.menuRender(this.menuTree)}
        </Menu>
        <div className={styles.trigger}>
          <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} onClick={toggle} className={styles.action}/>
        </div>
      </div>
    );
  }
}
