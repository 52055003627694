import axios from 'axios';
import { log } from './tool';

const baseURL = process.env.CREATIVE_URL || 'https://api.panda.palmax.com/v1';

const request = axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json' },
  transformRequest: [
    data => {
      if (data instanceof FormData) {
        return data;
      }
      return JSON.stringify(data);
    }
  ]
  // withCredentials: true
  // `transformResponse` allows changes to the response data to be made before
  // it is passed to then/catch
  // transformResponse: [
  //   data => {
  //     // Do whatever you want to transform the data
  //     const res = JSON.parse(data);
  //     return res;
  //   },
  // ],
});

let url: string;
let time: number;

request.interceptors.request.use(
  config => {
    url = config.url!;
    time = Date.now();

    // token
    // config.headers.Authorization = `Bearer ${localStorage.getItem('token') || ''}`;
    config.headers.Token = `${localStorage.getItem('token') || ''}`;

    return config;
  },
  err => Promise.reject(err)
);

request.interceptors.response.use(
  response => {
    log(`Request for ${url}, took ${Date.now() - time} ms.`);
    log(`response data: ↓`, '#fff', '#4169E1');
    console.log(response.data);

    return response;
  },
  error => {
    if (error && error.response && error.response.status === 401) {
      window.location.replace(window.location.origin + '/#/login');
    }
    return Promise.reject(error.response);
  }
);

export const http = request;
