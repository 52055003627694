import { action, observable, runInAction } from 'mobx';
import { userApi } from '../services/user.service';
import { IUserInfo } from 'interface';
import base32Encode from 'base32-encode';
import shajs from 'sha.js';

export class UserStore {
  /** Convert String to Uint8Array */
  private str2Uint8Array(input: string): Uint8Array {
    const encoder = new TextEncoder();
    const view = encoder.encode(input);
    return view;
  }

  // 用户密码加密方式：http://wiki.palmax.cn/pages/viewpage.action?pageId=29328417
  private encrypt(password: string): string {
    const pwdBytes = this.str2Uint8Array(password);
    const offset = (pwdBytes.length % 3) + 1;

    const salt = [];
    for (let i = 0; i < password.length; i += offset + 1) {
      salt.push(password[i]);
    }
    const saltBytes = this.str2Uint8Array(salt.join(''));

    const digest = shajs('sha1')
      .update(saltBytes)
      .update(pwdBytes)
      .digest();

    // const base32Encode = require('base32-encode');
    return base32Encode(digest, 'RFC4648', { padding: false });
  }

  @observable
  public loading: boolean = false;

  @observable
  public username: string = '';

  @observable
  public userInfo: IUserInfo;

  @action
  public login = async (data: {
    username: string;
    password: string;
  }): Promise<{ success: boolean; error?: string }> => {
    this.loading = true;

    data.password = this.encrypt(data.password);

    try {
      const res = await userApi.login(data);

      console.log(JSON.stringify(res));

      runInAction(() => {
        this.username = res.username;
        this.loading = false;
        this.userInfo = res;

        localStorage.setItem('token', res.token);
      });

      return {
        success: true
      };
    } catch (e) {
      runInAction(() => {
        this.loading = false;
      });

      return {
        error: '用户名或密码错误',
        success: false
      };
    }
  };
}

export const userStore = new UserStore();
