import { IKV } from 'interface';
import { AxiosRequestConfig, Method } from 'axios';

interface IHtttpDecoratorOptions {
  url: string;
  headers?: IKV;
  timeout?: number;
  data?: any;
  params?: any;
  responseType?:string;
}

export type HandlePanic<T> = (data: T) => T;

const buildDecorator = (method: Method, options: IHtttpDecoratorOptions): MethodDecorator => (
  target,
  propertyKey,
  descriptor: TypedPropertyDescriptor<any>
) => {
  const oldVal = descriptor.value;
  descriptor.value = (...args: [any, IKV]) => {
    const [params, headers] = args;
    const axiosOpt: AxiosRequestConfig = {
      method
    };
    Object.assign(axiosOpt, options);

    if (typeof params !== 'string') {
      if (method === 'get' || method === 'put') {
        Object.assign(axiosOpt, { params });
      } else {
        Object.assign(axiosOpt, { data: params });
      }
    } else {
      axiosOpt.url = `${axiosOpt.url}/${params}`;
    }

    if (headers) {
      options.headers = Object.assign(options.headers || {}, headers);
    }

    return oldVal.apply(target, [axiosOpt, target]);
  };
};

/**
 * @GET({
 *  url:'/user'
 *  header:{}
 * })
 * @param options
 */
export function GET(options: IHtttpDecoratorOptions) {
  const method = 'get';
  return buildDecorator(method, options);
}

/**
 * @POST({
 *  url:'/user'
 *  header:{}
 * })
 * @param options
 */
export function POST(options: IHtttpDecoratorOptions) {
  const method = 'post';
  return buildDecorator(method, options);
}

/**
 * @PUT({
 *  url:'/user'
 *  header:{}
 * })
 * @param options
 */
export function PUT(options: IHtttpDecoratorOptions) {
  const method = 'put';
  return buildDecorator(method, options);
}

/**
 * @DELETE({
 *  url:'/user'
 *  header:{}
 * })
 * @param options
 */
export function DELETE(options: IHtttpDecoratorOptions) {
  const method = 'delete';
  return buildDecorator(method, options);
}
