import * as React from 'react';
import styles from './index.module.scss';

export const Loading: React.SFC<any> = () => (
  <div
    style={{
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      minHeight: 'calc(100vh - 100px)'
    }}
  >
    <div className={styles.lineScale}>
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
);
