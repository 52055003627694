import { history } from 'utils/history';
import { Modal } from 'antd';

export function signOut() {
  Modal.confirm({
    title: '是否退出登录?',
    onOk() {
      localStorage.removeItem('token');
      history.push('/login');
    },
    onCancel() {}
  });
}
