import { observable, action } from 'mobx';
import { Location } from 'history';

export class RouterStore {
  @observable
  currentRouter: Location;

  @action.bound
  update(route: Location) {
    this.currentRouter = route;
  }
}

export const routerStore = new RouterStore();
