import { IMenuTree, IMenu } from 'config/loadable.config';

/**
 * 多重继承
 *
 * @export
 * @param {*} derivedCtor
 * @param {any[]} baseCtors
 */
export function applyMixins(derivedCtor: any, baseCtors: any[]) {
  baseCtors.forEach(baseCtor => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
      derivedCtor.prototype[name] = baseCtor.prototype[name];
    });
  });
}

/**
 * 打印日志
 *
 * @param {string} msg
 * @param {string} [color='#fff']
 * @param {string} [background='#f44336']
 */
export function log(msg: string, color = '#fff', background = '#f44336') {
  console.log(`%c ${msg}`, `color:${color};background:${background}`);
}

/**
 * menu数组转tree数组
 *
 * @export
 * @param {IMenu[]} menu
 * @param {number} [len=1]
 * @param {IMenuTree[]} [r=[]]
 * @returns {IMenuTree[]}
 */
export function handleTree(menu: IMenu[], len: number = 1, r: IMenuTree[] = []): IMenuTree[] {
  const children: IMenu[] = [];
  const o: IMenu[] = [];

  menu.forEach(i => {
    if (i.parentKey) {
      if (i.parentKey.length === len) {
        children.push({ ...i });
      } else {
        o.push({ ...i });
      }
    } else {
      r.push({ ...i });
    }
  });

  r.forEach(j => {
    handleChildTree(j, children);
  });

  if (o.length > 0) {
    handleTree(o, len + 1, r);
  }

  return r;
}

/**
 * 递归 ChilTree
 *
 * @param {IMenuTree} node
 * @param {IMenu[]} children
 */
function handleChildTree(node: IMenuTree, children: IMenu[]) {
  if (node.children) {
    node.children.forEach(i => {
      handleChildTree(i, children);
    });
  } else {
    node.children = [];
    children.forEach(i => {
      if (i.parentKey === node.key) {
        node.children!.push(i);
      }
    });
  }
}

/**
 *
 *
 * @export
 * @param {string} target
 * @param {string} matched
 */
export function matchingRate(target: string, matched: string) {
  if (!target || !matched) {
    return 0;
  }

  const targets = target.split('');
  const matcheds = matched.split('');
  const len = Math.min(targets.length, matcheds.length);
  let r = 0;

  for (let i = 0; i < len; i++) {
    if (targets[i] === matched[i]) {
      r++;
    } else {
      break;
    }
  }

  return r;
}
