/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-09 15:43:40
 * @LastEditTime: 2019-08-20 10:17:28
 * @LastEditors: Please set LastEditors
 */
// 订单状态
export enum ORDER_STATUS {
  pending = 1,
  processed,
  processing,
}

export enum ORDER_STATUS_STRING {
  '待处理' = 1,
  '已处理',
  '处理中',
}

// 物流状态
export enum LOGISTICS_STATUS {
  unShipped = 1,
  partialShipped,
  shipped,
}

export enum LOGISTICS_STATUS_STRING {
  '未发货' = 1,
  '部分发货',
  '已发货',
}

// 请求订单 API 参数
export enum ORDER_API_PARAMS {
  shopId = 'shop_id',
  orderStatus = 'order_status',
  shopifyOrderId = 'order_id',
  orderId = 'id',
  logisticsStatus = 'fulfillment_status',
  shippingNumber = 'shipping_number',
  shopifyProductId = 'product_id',
  startDate = 'stime',
  endDate = 'etime',
}

// 请求订单操作日志 API 参数
export enum ORDER_OPERATION_API_PARAMS {
  startDate = 'stime',
  endDate = 'etime',
}

// 采购单状态
export enum PROCUREMENT_STATUS {
  purchasedUnshipped = 1,
  exception,
  partialShipped,
  shipped,
}

// 生成采购单 API 参数
export enum PURCHASE_ORDER_API_PARAMS {
  startDate = 'stime',
  endDate = 'etime',
}

export enum PROCUREMENT_STATUS_STRING {
  '已采购未发货' = 1,
  '异常',
  '部分发货',
  '已发货',
}
