import { http } from 'utils/http';
import { GET } from 'utils/decorators/http';

class CommonApi {
  /**
   * 店铺列表
   * @param opt
   */
  @GET({
    url: '/shop'
  })
  async fetchShopList<T = any>(opt?: any) {
    const res = await http.request<T>(opt);
    return res.data;
  }
}

export const commonApi = new CommonApi();
