/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-09 15:43:40
 * @LastEditTime: 2019-08-12 15:54:03
 * @LastEditors: Please set LastEditors
 */
import { http } from 'utils/http';
import { GET, POST } from 'utils/decorators/http';
// import { async } from 'q';

class OrderApi {
  // 获取订单列表
  @GET({
    url: '/order',
  })
  async fetchOrderDate(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // 更新物流状态
  @POST({
    url: '/order',
  })
  async updateLogisticsStatus(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // 重新生成出库单
  @GET({
    url: 'order/reload',
  })
  async reloadData(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // 获取物流单号
  @GET({
    url: 'order/getwsecode',
  })
  async fetchShippingNumber(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  @GET({
    url: 'order/logistics_company',
  })
  async fetchShippingCompanyList(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // 查找所有相关的物流单号
  @GET({
    url: 'order/shipping_number',
  })
  async fetchShippingNumberList(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  @GET({
    url: 'order/order_id',
  })
  async fetchShopifyOrderIdList(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  @GET({
    url: 'order/product_id',
  })
  async fetchProductIdList(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  @GET({
    url: 'order/id',
  })
  async fetchOrderIdList(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // 订单操作日志
  @GET({
    url: '/order/log',
  })
  async fetchOperationLog(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  // 导出发货单
  @GET({
    url: '/order/download_invoice',
    responseType: 'blob',
  })
  async downloadInvoice(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }
}

export const orderApi = new OrderApi();
