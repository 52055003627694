/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-09 15:43:40
 * @LastEditTime: 2019-08-20 10:26:27
 * @LastEditors: Please set LastEditors
 */
import { observable, action, runInAction } from 'mobx';
import { procurementApi } from '../services/procurement.service';
import { PURCHASE_ORDER_API_PARAMS } from '../utils/enum';
import { message } from 'antd';
import moment from 'moment';

interface ProductItem {
  product_id: string;
  quantity: number;
  real_quantity: number;
  sku: string;
  sku_id: string;
  title_cn: string;
  title_en: string;
}

interface PurchaseData {
  id: number;
  number: string;
  items: ProductItem[];
  purchase_buyer: number;
  purchase_status: number;
  remark: string;
  shipping_name: string;
  shipping_number: string;
  created_at: number;
}

const { startDate, endDate } = PURCHASE_ORDER_API_PARAMS;

export class ProcurementStore {
  // 过滤列表中所有的可选项
  @observable
  filterData: {
    purchase_name: Array<string>;
    order_id: Array<string>;
    sku: Array<string>;
  } = {
    purchase_name: [],
    order_id: [],
    sku: [],
  };

  @observable
  filterDataGen: { order_id: Array<string> } = {
    order_id: [],
  };
  // 生成采购单 list 数量
  @observable proDataNum = 0;
  // 生成采购单 table数据
  @observable
  procurementData = [];

  // 下载 loading
  @observable
  downloadLoading = false;

  // 采购单列表 list 数量
  @observable
  proListNum = 0;
  @observable
  defaultPageSize = 10;
  // 采购单列表table数据
  @observable
  procurementListData: PurchaseData[] = [];

  @observable
  ProcurementDetail: {
    created_at: number;
    id: number;
    quantity: number;
    order_item: Array<{
      email: string;
      order_id: number;
      order_price: number;
      shipping_address: string;
      shopify_created_at: string;
    }>;
    purchase_item: Array<{
      name: string;
      product_id: string;
      quantity: number;
      sku: string;
      sku_name: string;
      sku_image: string;
      vendor: string;
      title_cn: string;
    }>;
  };

  @observable
  loadingCreate = false;
  @observable
  loadingList = false;
  @observable
  loadingDetail = false;

  // 更新采购单状态相关
  @observable
  updateStatusLoading = false;
  @observable
  purchaseStatusList = [];
  @observable
  purchaseStatusLoading = false;
  @observable
  shippingCompanyList = [];
  @observable
  shippingCompanyLoading = false;
  @observable
  buyerList = [];
  @observable
  buyerLoading = false;

  // 获取订单列表，新建采购单页面使用
  @action
  public refreashList = async (params: any) => {
    this.loadingCreate = true;
    //  "fulfillment_status": 1   未发货
    // "order_status": 1, // 1 待处理
    try {
      const res = await procurementApi.orderForProcurement(params);
      runInAction(() => {
        this.loadingCreate = false;
        this.procurementData = res.data.filter(
          (v: any) => v.fulfillment_status === 1
        );
        this.proDataNum = res.total;
      });
      return res;
    } catch (e) {
      runInAction(() => {
        this.loadingCreate = false;
        message.error('获取订单列表失败');
      });
      return {
        error: '获取订单列表失败',
        success: false,
      };
    }
  };

  // 获取采购单列表
  @action
  public getList = async (params: any) => {
    this.loadingList = true;
    try {
      const res = await procurementApi.getProList(params);
      const dataList: PurchaseData[] = res.data.map((d: PurchaseData) => {
        const items: ProductItem[] = d.items
          ? d.items.map((item: ProductItem) => {
              return {
                product_id: item.product_id,
                quantity: item.quantity,
                real_quantity: item.real_quantity,
                sku: item.sku,
                sku_id: item.sku_id,
                title_cn: item.title_cn,
                title_en: item.title_en,
              };
            })
          : [];

        return {
          id: d.id,
          number: d.number,
          items,
          purchase_buyer: d.purchase_buyer,
          purchase_status: d.purchase_status,
          remark: d.remark,
          shipping_name: d.shipping_name,
          shipping_number: d.shipping_number,
          created_at: d.created_at,
        };
      });

      runInAction(() => {
        this.loadingList = false;
        this.procurementListData = dataList;
        this.proListNum = res.total;
      });
      return res;
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.loadingList = false;
        message.error('获取采购单列表失败');
      });
      return {
        error: '获取采购单列表失败',
        success: false,
      };
    }
  };

  //下载采购单
  @action
  public downloading = async (params: { id: string }) => {
    this.downloadLoading = true;
    const paramsWithTime = Object.assign({ time: new Date().getTime()}, params);
    try {
      const res = await procurementApi.downloading(paramsWithTime);
      runInAction(() => {
        const element = document.createElement('a');
        const file = new Blob([res]);
        element.href = URL.createObjectURL(file);
        element.download = `采购明细表_${moment().format('YYYYMMDDHHmm')}.xlsx`;
        element.click();
        this.downloadLoading = false;
      });
      return res;
    } catch (e) {
      runInAction(() => {
        this.downloadLoading = false;
      });
      // 获取错误信息
      // let getError = async (data: any) => {
      //   return new Promise(resolve => {
      //     let reader = new FileReader();
      //     reader.onload = (eve: any) => {
      //       const errData = JSON.parse(eve.target.result);
      //       const error = errData.message
      //         ? `导出采购明细表失败，ERROR：${errData.message}`
      //         : '导出采购明细表失败，请稍后再试';
      //       resolve(error);
      //     };
      //     reader.readAsText(data);
      //   });
      // };
      // const error = await getError(e.data);
      return {
        error: '导出采购明细表失败，请稍后再试',
        // error,
        success: false,
      };
    }
  };

  // 获取采购详情
  @action
  public getProcurementDetail = async (params: string) => {
    // procurementApi.procurementDetail(params)
    this.loadingDetail = true;
    try {
      const res = await procurementApi.procurementDetail(params);
      runInAction(() => {
        this.loadingDetail = false;
        // 处理sort
        const list = res.purchase_item.map(
          (
            v: {
              name: string;
              quantity: number;
              product_id: string;
              sku: string;
              sku_name: string;
              sku_image: string;
              vendor: string;
              title_cn: string;
            },
            index: number
          ) => Object.assign(v, { sort: index + 1 })
        );
        res.purchase_item = list;
        this.ProcurementDetail = res;
      });
      return res;
    } catch (e) {
      runInAction(() => {
        this.loadingDetail = false;
        message.error('获取采购单详情失败');
      });
      return {
        error: '获取采购单详情失败',
        success: false,
      };
    }
  };

  // 生成采购单
  @action
  public create = async (params: { 
    [startDate]?: string,
    [endDate]?: string,
   }) => {
    // procurementApi.create(params);
    try {
      await procurementApi.create(params);
      runInAction(() => {
        message.success('生成采购单成功');
      });
      return {
        success: true,
      };
    } catch (e) {
      runInAction(() => {
        message.error('生成采购单失败');
      });
      return {
        error: '生成采购单失败',
        success: false,
      };
    }
  };

  // 重新生成采购单
  @action
  public reCreate = async (params: { id: string }) => {
    // return procurementApi.reCreate(params);

    try {
      const res = await procurementApi.reCreate(params);
      runInAction(() => {});
      return res;
    } catch (e) {
      runInAction(() => {
        message.error('重新生成采购单失败');
      });
      return {
        error: '重新生成采购单失败',
        success: false,
      };
    }
  };

  // 获取筛选条件
  @action
  public getFilter = async (params: { pos: string }) => {
    try {
      const res = await procurementApi.filter(params);
      runInAction(() => {
        if (params.pos === 'gen') {
          this.filterDataGen = res;
        } else {
          this.filterData = res;
        }
      });
      return res;
    } catch (e) {
      runInAction(() => {
        message.error('网络错误');
      });
      return {
        error: '获取筛选条件失败',
        success: false,
      };
    }
  };

  // 获取采购状态列表
  @action
  public getPurchaseStatus = async () => {
    if (this.purchaseStatusList.length) {
      return;
    }
    this.purchaseStatusLoading = true;
    try {
      const res = await procurementApi.fetchPurchaseStatus();
      runInAction(() => {
        this.purchaseStatusList = res.data.map(
          (item: { status: string; name: string }) => {
            return { key: item.status, value: item.name };
          }
        );
        this.purchaseStatusLoading = false;
      });
      return { success: true };
    } catch (e) {
      runInAction(() => {
        this.purchaseStatusLoading = false;
      });
      return {
        error: '获取采购状态列表失败',
        success: false,
      };
    }
  };

  // 获取物流公司列表
  @action
  public getShippingCompany = async () => {
    this.shippingCompanyLoading = true;
    try {
      const res = await procurementApi.fetchShippingCompany();
      runInAction(() => {
        this.shippingCompanyList = res.data.map(
          (item: { code: string; name: string }) => {
            return { key: item.code, value: item.name };
          }
        );
        this.shippingCompanyLoading = false;
      });
      return { success: true };
    } catch (e) {
      runInAction(() => {
        this.shippingCompanyLoading = false;
      });
      return {
        error: '获取物流公司列表失败',
        success: false,
      };
    }
  };

  // 获取采购员列表
  @action
  public getBuyer = async () => {
    if (this.buyerList.length) {
      return;
    }
    this.buyerLoading = true;
    try {
      const res = await procurementApi.fetchBuyer();
      runInAction(() => {
        this.buyerList = res.data.map(
          (item: { buyer_id: string; name: string }) => {
            return { key: item.buyer_id, value: item.name };
          }
        );
        this.buyerLoading = false;
      });
      return { success: true };
    } catch (e) {
      runInAction(() => {
        this.buyerLoading = false;
      });
      return {
        error: '获取采购员列表失败',
        success: false,
      };
    }
  };

  // 更新采购状态
  @action
  public updatePurchaseStatus = async (params: {
    id: number;
    status: number | undefined;
    sku_id: number | undefined;
    real_quantity: number | undefined;
    shipping_code: number | undefined;
    shipping_number: string | undefined;
    buyer: number | undefined;
    remark: string | undefined;
  }) => {
    this.updateStatusLoading = true;
    try {
      await procurementApi.updatePurchaseStatus(params);
      runInAction(() => {
        this.updateStatusLoading = false;
      });

      return { success: true };
    } catch (e) {
      runInAction(() => {
        this.updateStatusLoading = false;
      });

      const error = `更新采购单状态失败，ERROR： ${e.data.message}`;
      return {
        error,
        success: false,
      };
    }
  };
}

export const procurementStore = new ProcurementStore();
